import * as React from 'react';
import { FormattedMessage } from '@liveauctioneers/hammer-ui-core/intl';
import styled from 'styled-components';
import Tooltip, { Header, Message } from '@liveauctioneers/caterwaul-components/lib/Tooltip/Tooltip';

type Props = {
    className?: string;
    iconSize?: 'small' | 'large';
};

const TopBadge = ({ className = '', iconSize = 'large' }: Props) => {
    return (
        <Tooltip
            placement="bottom"
            trigger={
                <StyledTopRatedTrigger className={className}>
                    <StyledImage
                        $small={iconSize === 'small'}
                        alt="badge"
                        height={iconSize === 'small' ? 18 : 24}
                        src="https://p1.liveauctioneers.com/dist/images/badge.svg"
                        width={iconSize === 'small' ? 18 : 25}
                    />{' '}
                    <FormattedMessage
                        id="top"
                        tagName="span"
                    />
                </StyledTopRatedTrigger>
            }
        >
            <Header>
                <FormattedMessage id="top_rated_header" />
            </Header>
            <Message last>
                <FormattedMessage id="top_message" />
            </Message>
        </Tooltip>
    );
};

export default React.memo<Props>(TopBadge);

const StyledImage = styled.img<{ $small: boolean }>`
    height: ${({ $small }) => ($small ? 18 : 24)}px;
    margin-right: 4px;
`;

const StyledTopRatedTrigger = styled.span`
    && {
        width: 106px;
        font-family: ${({ theme }) => theme.typography.fontPrimaryStrong};
        font-size: 12px;
        color: ${({ theme }) => theme.colors.orange100};
        vertical-align: middle;
        display: inline-flex;
        justify-content: space-evenly;
        align-items: center;
    }
`;
