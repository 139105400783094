import * as React from 'react';
import { FormattedMessage, FormattedNumber } from '@liveauctioneers/hammer-ui-core/intl';
import isEqual from 'react-fast-compare';
import SeeAllLink from '../SeeAllLink/SeeAllLink';
import StarRating from '@liveauctioneers/caterwaul-components/lib/StarRating/StarRating';
import styled from 'styled-components';

type Props = {
    className?: string;
    hideReviewCount?: boolean;
    onClick?: (event: any) => void;
    rating: number;
    reviewCount?: number;
    small?: boolean;
    to?: string;
};

const RatingDisplaySmall = ({ className, hideReviewCount, onClick = null, rating, reviewCount, small, to }: Props) => {
    return rating >= 0 ? (
        <RatingContainer
            $clickable={Boolean(onClick)}
            className={className}
            onClick={onClick}
        >
            <StarRating
                rating={rating}
                small={small}
            />
            {!hideReviewCount && (
                <SeeAllLink
                    text={
                        <FormattedMessage
                            id="review.review_count"
                            values={{
                                count: (
                                    <FormattedNumber
                                        key="review_count"
                                        value={reviewCount}
                                    />
                                ),
                                reviewCount,
                            }}
                        />
                    }
                    url={to}
                />
            )}
        </RatingContainer>
    ) : (
        <div>
            <span>N/A</span>
        </div>
    );
};

export default React.memo<Props>(RatingDisplaySmall, isEqual);

const RatingContainer = styled.div<{ $clickable: boolean }>`
    cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'default')};
    flex: 1 1 auto;
    display: flex;
    flex-flow: row wrap;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    @media (max-width: ${({ theme }) => theme.breakpoints.tabletNarrowWidth}) {
        flex-direction: row;
    }
`;
